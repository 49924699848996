<div ufmasterdetail class="container">
    <uf-panel *ngIf="tableConfig && datasource" [bottomThreshold]="150" [class.panelWidth]="showFilters"
        (scrollBottom)="pageTable.load()" class="container">
        <div class="wrapper">
            <div [class.disable-scroll]="showFilters" class="matrix-panel">
                <div class="header-pane uf-app-bar flat stretch">
                    <uf-breadcrumbs [breadcrumbs]="breadcrumbs" />
                </div>

                <div class="uf-grid condensed column-gap-sm pad-sides">
                    <div class="col-12">
                        <uf-filter-display [manager]="filterManager" [value]="filterValues"
                            (valueChange)="filtersChange()" />
                    </div>
                    <div *ngFor="let diagram of gridData" class="col-lg-6">
                        <h2>{{ diagram.title }}</h2>
                        <div class="matrix-container">
                            <div *ngIf="config && diagram.data && diagram.labels" class="matrix">
                                <div [ngStyle]="{flex: 0.5}" class="matrix-y-axis-label">
                                    <div [ngStyle]="{flex: gridSize}" class="label">
                                        <span>{{ diagram.yAxisLabel }}</span>
                                    </div>
                                    <div [ngStyle]="{flex: 2}"></div>
                                </div>
                                <div [ngStyle]="{flex: gridSize + 1.5}" class="matrix-body">
                                    <div *ngFor="let row of diagram.data; let i = index" [ngStyle]="{flex: 1}"
                                        class="matrix-row">
                                        <div [ngStyle]="{flex: 1.5}" class="label">
                                            <span>{{ diagram.labels.columns[i] }}</span>
                                        </div>

                                        <div *ngFor="let column of row; let j = index"
                                            [ngStyle]="{flex: 1, backgroundColor: colorsLookup[(config[i] ?? [])[j]?.colour ?? ''] || (config[i] ?? [])[j]?.colour}"
                                            class="cell">
                                            <span class="small-number">{{ (config[i] ?? [])[j]?.risk }}</span>
                                            <span class="large-number">{{ column || '' }}</span>
                                        </div>
                                    </div>
                                    <div [ngStyle]="{flex: 1.5}" class="matrix-row labels">
                                        <div [ngStyle]="{flex: 1.5}"></div>
                                        <div *ngFor="let label of diagram.labels.rows" [ngStyle]="{flex: 1}"
                                            class="label vertical">
                                            <span>{{ label }}</span>
                                        </div>
                                    </div>
                                    <div [ngStyle]="{flex: 0.5}" class="matrix-x-axis-label">
                                        <div [ngStyle]="{flex: 2}"></div>
                                        <div [ngStyle]="{flex: gridSize}" class="label">
                                            <span>{{ diagram.xAxisLabel }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 pad-top">
                        <div class="risk-matrix-table-buttons">
                            <button (click)="downloadTableAsCsv()" type="button" class="uf-action tertiary">
                                <uf-icon name="download" />
                            </button>
                        </div>
                        <uf-table #pageTable [config]="tableConfig" [datasource]="datasource" [sort]="sort"
                            class="accent" />
                    </div>
                </div>
            </div>

        </div>


    </uf-panel>

    <uf-drawer [showHandle]="true" [(open)]="showFilters" handleIcon="filter" class="share-space">
        <uf-filter-inputs *ngIf="filterManager" [manager]="filterManager" [value]="filterValues"
            (valueChange)="filtersChange()" (onClose)="showFilters = false" />
    </uf-drawer>
</div>