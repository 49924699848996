<ng-template [ngIf]="challenge" [ngIfElse]="select" [ngSwitch]="challenge">

    <uf-virtual-mfa *ngSwitchCase="mfaChallengeType.Virtual" [label]="label" [issuer]="issuer" [mfaStatus]="mfaStatus"
        [setVirtualMfaCode]="setVirtualMfaCode.bind(this)" [verifyToken]="verifyVirtualMfaToken.bind(this)" />

    <uf-recovery-codes *ngSwitchCase="mfaChallengeType.RecoveryCode" [mfaStatus]="mfaStatus"
        [setRecoveryCodes]="setRecoveryCodes.bind(this)" [verifyCode]="verifyRecoveryCode.bind(this)" />

    <uf-sms *ngSwitchCase="mfaChallengeType.Sms" [mfaStatus]="mfaStatus" [smsChallenges]="smsChallenges.bind(this)"
        [verifyCode]="verifySmsCode.bind(this)" />

    <uf-web-authn *ngSwitchCase="mfaChallengeType.Device" [mfaStatus]="mfaStatus"
        [setupCredential]="setupCredential.bind(this)" [getSetupChallenge]="getSetupChallenge.bind(this)"
        [getVerifyChallenge]="getVerifyChallenge.bind(this)" [verifyCredential]="verifyCredential.bind(this)" />

</ng-template>

<ng-template #select>
    <uf-mfa-select [mfaStatus]="mfaStatus" [mfaAcceptedChallenge]="acceptedChallenges"
        (selectProvider)="selectProvider($event)" />
</ng-template>

<div class="col center-all">

    <button *ngIf="challenge && acceptedChallenges.length > 1" (click)="challenge = undefined" type="button"
        class="uf-button tertiary">
        {{commonTK.MfaChooseMethod | translate}}
    </button>

    <button (click)="logout()" type="button" class="uf-button tertiary">
        {{sharedTermsTK.ActionLogout | translate}}
    </button>
</div>